import React, { Fragment, useEffect, useState } from "react";
import "./styles.css";
import { authApi, healthEducationApi } from "./Apis";
import { Modal } from "./Modal";

export const Articles = ({
  documentId,
  sectionId,
  refreshToken,
  authToken,
}) => {
  const [articleData, setArticleData] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [activeModalId, setActiveModalId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(authToken);
  const [refToken, setRefToken] = useState(refreshToken);
  const [, forceUpdate] = useState(0);

  useEffect(() => {
    fetchArticle();
  }, [token]);

  const attachEventListeners = () => {
    window.addEventListener("locationchange", (event) => {
      window.location.reload();
    });

    let links = document.getElementsByTagName("a");
    for (let i = 0; i < links.length; i++) {
      if (
        links[i].dataset?.documentType === "Definition" ||
        links[i].dataset?.description?.includes("MultiMedia video")
      ) {
        links[i].addEventListener("click", (event) => {
          setActiveModalId(event.currentTarget.dataset.hwid);
          setShowModal(true);
        });
      }
    }
  };
  const updateHrefs = () => {
    const links = document.getElementsByTagName("a");
    if (links.length) {
      for (let i = 0; i < links.length; i++) {
        if (links[i].dataset?.articleid) {
          if (
            links[i].dataset?.documentType === "Definition" ||
            links[i].dataset?.description?.includes("MultiMedia video")
          ) {
            links[i].href =
              "/article/" +
              links[i].dataset.articleid +
              "/" +
              refreshToken +
              "/" +
              authToken;
          } else if (
            links[i].dataset?.documentType === "Focus" ||
            links[i].dataset?.documentType === "Actionset" ||
            links[i].dataset?.documentType === "Special" ||
            links[i].dataset?.documentType === "MedicalTest" ||
            links[i].dataset?.documentType === "Symptom"
          ) {
            links[i].href = "/document/" + links[i].dataset.articleid;
          }
        }
      }
    }
  };

  const getAuth = () => {
    authApi
      .post("/auth/refresh_token", {
        refreshToken: refToken,
      })
      .then((response) => {
        if (response?.data?.accessToken) {
          localStorage.setItem("authToken", response.data.accessToken);
          localStorage.setItem("refreshToken", response.data.refreshToken);
          setToken(response.data.accessToken);
          setRefToken(response.data.refreshToken);
        } else {
          setIsLoading(false);
        }
      })
      .catch(console.log);
  };

  const fetchArticle = () => {
    setIsLoading(true);
    healthEducationApi.defaults.headers.Authorization = `Bearer ${token}`;
    const articleId = window.location.pathname.split("/")[2];

    healthEducationApi
      .get(
        `/content/articles/${articleId || documentId}/en-us/?alljsinline=true`
      )
      .then((response) => {
        if (response?.response?.status === 401) {
          getAuth();
        } else if (response?.data) {
          setArticleData(response.data);
          updateBody(response.data.pageHtml.bodyHtml);
          updateHead(
            response.data.pageHtml.headHtml.replaceAll(
              "alpine.rhtml.kbnet.awesome",
              "alpine.rhtml.kbnet.awesome.neutral"
            )
          );
          updateJs(response.data.resources.inline);
          setTimeout(() => {
            // addContinueReading();
            updateHrefs();
            attachEventListeners();
            updateSelectInputs();
            forceUpdate(1);
          }, 2000);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.warn(err);
      });
  };

  return isLoading ? (
    <div className="Loader-wrapper">
      <div className="Loader">Loading...</div>
    </div>
  ) : (
    <div className="Articles-wrapper">
      {!!articleData ? (
        <Fragment>
          <div className="Header-section">
            <h2>{articleData.title}</h2>
            <p>{articleData.abstract}</p>
            <div>
              <p>{articleData.credits.author.name}&nbsp; &bull;</p>
              <p>{articleData.certifiedDate}</p>
            </div>
          </div>
        </Fragment>
      ) : (
        <div className="Empty-data-wrapper">
          <h2>Something went wrong.</h2>
          <p>Please refresh the page.</p>
        </div>
      )}
      {showModal && (
        <Modal
          getAuth={getAuth}
          setShowModal={setShowModal}
          activeModalId={activeModalId}
          setActiveModalId={setActiveModalId}
        />
      )}
    </div>
  );
};

const addContinueReading = () => {
  let contReadingWrapper = document.createElement("div");
  contReadingWrapper.className = "Continue-reading-wrapper";
  contReadingWrapper.id = "Continue-reading-wrapper";
  let contReadingButton = document.createElement("button");
  let textnode = document.createTextNode("Continue Reading");
  contReadingButton.onclick = continueReading;
  contReadingButton.appendChild(textnode);
  contReadingWrapper.appendChild(contReadingButton);

  let contentElement = document.getElementsByClassName("HwContent")[0];
  if (
    contentElement &&
    contentElement.offsetHeight > window.innerHeight * 1.5
  ) {
    contentElement.style.height = "200vh";
    contentElement.style.overflowY = "hidden";
    contentElement.parentNode.insertBefore(
      contReadingWrapper,
      contentElement.nextSibling
    );

    // Trigger continueReading fn. on accordion clicks
    // Uncomment when using "continue reading"

    // let accordionButtons = document.querySelectorAll(
    //   "button.HwNavigationAccordion"
    // );
    // [...accordionButtons].forEach(() =>
    //   addEventListener("click", continueReading)
    // );
  }
};

const continueReading = () => {
  let contReadingWrapper = document.getElementsByClassName(
    "Continue-reading-wrapper"
  )[0];
  contReadingWrapper.style.display = "none";
  const element = document.getElementsByClassName("HwContent")[0];
  element.style.height = "auto";
  element.style.overflowY = "auto";
};

const updateHead = (data) => {
  document.head.insertAdjacentHTML("beforeend", data);
};
const updateBody = (data) => {
  // document.body.insertAdjacentHTML("beforeend", data);
  document.getElementById("root").insertAdjacentHTML("beforeend", data);
};
const updateJs = (data) => {
  let script = document.createElement("script");
  script.type = "text/javascript";
  script.innerHTML = data;
  script.id = "inlineJs";
  document.body.appendChild(script);
  // window.org.healthwise.setConfig("video", {
  //   keyId: "innovaccerapis.test.kbvl",
  //   hash: "8290d29436cc68a12eac3214f0f9bf3b966ec0ab21da722d1bd54a8f9bf9",
  //   service: "https://media.healthwise.net",
  // });
};

const updateSelectInputs = () => {
  let selectInputs = document.getElementsByTagName("select");
  if (selectInputs) {
    for (let i = 0; i < selectInputs.length; i++) {
      selectInputs[i].size = 1;
    }
  }
};
