import React, { useEffect, useState } from "react";
import { authApi, healthEducationApi } from "./Apis";

export const Modal = ({ setShowModal, activeModalId, getAuth }) => {
  const localModalData = JSON.parse(
    localStorage.getItem(activeModalId) || null
  );
  const [loading, setloading] = useState(true);
  const [modalData, setModalData] = useState(localModalData);
  useEffect(() => {
    let modalContent = document.getElementById("modalData");
    if (modalData) {
      modalContent.insertAdjacentHTML("beforeend", modalData.pageHtml.bodyHtml);
      setloading(false);
      preventOutsideScroll();
    } else {
      healthEducationApi
        .get(`/content/articles/${activeModalId}/en-us/?alljsinline=true`)
        .then((response) => {
          if (response?.response?.status === 401) {
            getAuth();
          } else if (response?.data) {
            setModalData(response.data);
            let modalContent = document.getElementById("modalData");
            modalContent.insertAdjacentHTML(
              "beforeend",
              response.data.pageHtml.bodyHtml
            );
            if (response.data.doctype === "multimedia") {
              // Add javascript
              let script = document.createElement("script");
              script.type = "text/javascript";
              script.innerHTML = response.data.resources.inline;
              script.defer = true;
              script.id = "modalInlineJs";
              modalContent.appendChild(script);
              window.org.healthwise.setConfig("video", {
                keyId: "innovaccerapis.test.kbvl",
                hash: "8290d29436cc68a12eac3214f0f9bf3b966ec0ab21da722d1bd54a8f9bf9",
                service: "https://media.healthwise.net",
              });
              // let videoELement = document.querySelector(
              //   "#modalData .HwMediaInlineVidoes"
              // );
              // videoELement.style.visibility = "hidden";
            } else {
              localStorage.setItem(
                activeModalId,
                JSON.stringify(response.data)
              );
            }
            setloading(false);
            preventOutsideScroll();
          } else {
            setloading(false);
          }
        })
        .catch((err) => {
          setloading(false);
          console.warn(err);
        });
    }

    return allowOutsideScroll;
  }, []);

  return (
    <div id="myModal" className="modal">
      <div id="modal-content" className="modal-content">
        <span onClick={() => setShowModal(false)} className="close">
          &times;
        </span>
        <h3>{!loading && modalData.title}</h3>
        {loading && (
          <div className="Loader-wrapper Small-loader">
            <div className="Loader">Loading...</div>
          </div>
        )}
        <div id="modalData"></div>
      </div>
    </div>
  );
};

const preventOutsideScroll = () => {
  document.body.style.overflow = "hidden";
};

const allowOutsideScroll = () => {
  document.body.style.overflow = "auto";
};
