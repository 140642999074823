import Axios from 'axios';

const URL = 'https://inconnect.oninnovaccer.com/api/healthEducation/api';
const URL2 = 'https://inconnect.oninnovaccer.com/api/patientAuth/api/v1';

export const authApi = Axios.create({
  baseURL: URL2,
});

export const healthEducationApi = Axios.create({
  baseURL: URL,
  headers: {
    usertype: 'patient',
  },
});

authApi.interceptors.response.use(
  (res) => res,
  (err) => err
);

healthEducationApi.interceptors.response.use(
  (res) => res,
  (err) => err
);
